export const OFFER_BLOCK_HEADING = ['What ', 'we can do ', 'for you'];

export const TRAINING_BLOCK_HEADING = 'Trainings For';

export const TRAINING_ITEMS = [
  {
    icon: 'fundamentals',
    title: 'Fundamentals',
  },
  {
    icon: 'advanced',
    title: 'Advanced',
  },
  {
    icon: 'admin',
    title: 'Admin',
  },
  {
    icon: 'business-analyst',
    title: 'Business analyst',
  },
];

// CDAP

export const SERVICE_CDAP_HEADING = ['Enterprise On-Prem', 'Support for CDAP'];
export const FIRST_BLOCK_HEADING = 'Enterprise support for CDAP';
export const FIRST_BLOCK_FIRST_PARAGRAPH =
  'Cybervision delivers enterprise support for CDAP (Cask Data Application Platform), which is a powerful, Apache 2.0 licensed framework for building big data and analytics applications.';
export const FIRST_BLOCK_SECOND_PARAGRAPH =
  'Purpose-built for quick and easy data processing, it allows effortless accommodating and analyzing large volumes of data via a number of Hadoop distributions such as Hortonworks® Data Platform, MapR Converged Data Platform, and Cloudera Enterprise Data Hub.';
export const FIRST_BLOCK_THIRD_PARAGRAPH =
  'Clean, consistent no-code CDAP environment for visual data pipelines orchestration enables companies to derive real value from their data lakes across various sources.';
export const FIRST_BLOCK_FOURTH_PARAGRAPH =
  'The platform provides powerful tools to accelerate, fine-tune, and manage analytics solutions of varied complexity and scale, and has recently been set as a cornerstone of Google’s new big data analytics service - Google Cloud Data Fusion.';
export const OPTIONS_LEFT_BLOCK_HEADING = 'In the cloud';
export const OPTIONS_RIGHT_BLOCK_HEADING = 'On-premises';
export const OPTIONS_BLOCK_FIRST_ITEM =
  'Realize the full potential of your data with Google’s Cloud Data Fusion';
export const OPTIONS_BLOCK_SECOND_ITEM =
  'Convert your real-time data to insights with powerful GCP';
export const OPTIONS_BLOCK_THIRD_ITEM =
  'Integrate your solution with multiple Google services';
export const OPTIONS_BLOCK_FOURTH_ITEM =
  'Generate stream of operational insights from your data analytics with CDAP';
export const OPTIONS_BLOCK_FIFTH_ITEM =
  'Deploy your solution in multicloud / hybrid environment';
export const GET_STARTED = 'get started';
export const CONTACT_OUR_EXPERTS = 'contact our experts';
export const SERVICE_BLOCK_HEADING = 'service';
export const SERVICE_BLOCK_SUBHEADING =
  'We help companies achieve a competitive advantage by delivering full–stack enterprise support for CDAP';
export const OFFER_CARDS = [
  {
    title: 'Maintenance',
    icon: 'maintenance',
  },
  {
    title: 'Support',
    icon: 'support',
  },
  {
    title: 'Configuration',
    icon: 'configuration',
  },
  {
    title: 'Management',
    icon: 'management',
  },
];
export const OFFER_LIST_ITEMS = [
  'Infrastructure, data, or a business application transfer',
  'Data fetch from external data sources',
  'Cleansing and refining raw data',
  'Creating custom data pipelines',
  'Advanced analytics design and implementation',
  'Connecting an external analytics engine',
  'Custom software development',
];

// technical-support

export const TECHNICAL_SUPPORT_HEADING = ['Technical support'];
export const TECHNICAL_SUPPORT_DESCRIPTION = ['Multi-tiered technical support, system maintenance, and IT services for modern-day, dynamic organizations willing to streamline their operations and keep up with the fast pace of digital innovations'];
export const SUPPORT_MENU_TITLE = 'Technical support';
export const TECHNICAL_SUPPORT_SUBHEADING = 'Technical support & IT services';
export const TECHNICAL_SUPPORT_SOFTWARE_HEADING = 'Software support services';
export const TECHNICAL_SUPPORT_SOFTWARE_TEXT = 'System administration, technical support, and break-fix services for specific software products. CyberVision’s support engineers can perform installation and configuration of new software products for your organization, set up user management policies, run regular updates, support your users on maintenance, troubleshooting, and usability issues.';
export const TECHNICAL_SUPPORT_SOFTWARE_CONTENT = [
  {
    icon: 'soft-installation-updates',
    title: 'Software installation and updates'
  },
  {
    icon: 'remote-troubleshooting',
    title: 'Remote troubleshooting'
  },
  {
    icon: 'training-usability-assistance',
    title: 'Training and usability assistance'
  },
  {
    icon: 'user-management-security-policies',
    title: 'User management and security policies'
  },
  {
    icon: 'incident-based-support',
    title: 'Incident-based support'
  },
  {
    icon: 'app-data-migration',
    title: 'Application and data migration'
  },
  {
    icon: 'custom-apps',
    title: 'Support of custom applications'
  },
  {
    icon: 'organization-wide',
    title: 'Organization-wide IT support'
  }
];
export const TECHNICAL_SUPPORT_MULTI_TIERED_HEADING = 'Multi-tiered technical support';
export const TECHNICAL_SUPPORT_MULTI_TIERED_TEXT = 'A multi-tier incident management solution that encompasses software applications and dedicated tech support engineers to ensure efficient issue handling and escalation. CyberVision’s team can set up and configure necessary software and implement/staff Tier 0 - Tier 3 tech support routines for your organization - from online help for standard issues to technical research and development of solutions to previously unknown issues.';
export const TECHNICAL_SUPPORT_MULTI_TIERED_LIST = [
  'Online support, chatbots, and self-help portals', 'Incident management software and escalation rules',
  'Live user support', 'Technical issue resolution', 'In-depth technical assistance and troubleshooting',
  'R&D for complex technical issues'
];
export const TECHNICAL_SUPPORT_TROUBLESHOOTING_HEADING = 'Troubleshooting, maintenance, and technology upgrades';
export const TECHNICAL_SUPPORT_TROUBLESHOOTING_TEXT = 'Long-term or incident-based troubleshooting, back-up, system update, health check, upgrades, and maintenance services for your software applications. CyberVision’s engineers provide full-stack system investigation, maintenance, and break/fix services for all popular types of software.';
export const TECHNICAL_SUPPORT_TROUBLESHOOTING_CONTENT = [
  {
    icon: 'technology-planning',
    title: 'Technology planning'
  },
  {
    icon: 'retrofits-and-extensions',
    title: 'Retrofits and extensions'
  },
  {
    icon: 'test-and-inspection',
    title: 'System testing and inspection'
  },
  {
    icon: 'remote-troubleshooting',
    title: 'Remote troubleshooting'
  },
  {
    icon: 'performance-and-productivity-optimization',
    title: 'Performance and productivity optimization'
  },
  {
    icon: 'optimization-planning',
    title: 'Optimization planning'
  },
  {
    icon: 'system-improvements-and-integration',
    title: 'System improvements and integration'
  },
  {
    icon: 'system-upgrades-migration',
    title: 'System upgrades and migration'
  }
];
export const TECHNICAL_SUPPORT_MANAGED_HEADING = 'Managed IT services';
export const TECHNICAL_SUPPORT_MANAGED_TEXT = 'Managed IT and cloud infrastructure for your applications and technology stack. In addition to full-cycle technical support, CyberVision can take care of infrastructure management routines, cloud, security, and analytics solutions for your business.';
export const TECHNICAL_SUPPORT_MANAGED_CONTENT = [
  {
    icon: 'cloud-infrastructure',
    title: 'Managed cloud infrastructure'
  },
  {
    icon: 'saas',
    title: 'Managed SaaS applications'
  },
  {
    icon: 'security-management',
    title: 'Security management'
  },
  {
    icon: 'it-communications',
    title: 'IT communications'
  },
  {
    icon: 'data-analytics',
    title: 'Data analytics'
  },
  {
    icon: 'helpdesk',
    title: 'Helpdesk'
  }
];
export const FIRST_PARAGRAPH =
  'CyberVision offers technical support, maintenance, and IT services for a broad variety of operating systems, business support systems, network management systems, web portals, etc. We manage system integration and data migration, system customization and business process optimization.';
export const SECOND_PARAGRAPH =
  'Our support services rest upon our long-acquired expertise in the IT operations and administration in such industries like Telecom, Financial, Retail, Logistics, Healthcare, Digital Entertainment, Manufacturing, Science, and others.';
export const THIRD_PARAGRAPH =
  'The accumulated knowledge of business policies and key performance metrics for different types of enterprise allows us to deliver our support services efficiently and with a minimal run-up time.';
export const FOURTH_PARAGRAPH =
  'We also provide the day-to-day support of our own software solutions deployed at our clients and consult on integrations with the third-party solutions. Most importantly, we provide our system support services always in adherence to pre-agreed SLAs or target performance benchmarks.';
export const TECHNICAL_SUPPORT_ADVANTAGES_CONTENT = [
  {
    number: '01',
    text: '20+ years of experience in technical and IT support',
    title: 'Expertise'
  },
  {
    number: '02',
    text: 'Strong team of software engineers and support specialists',
    title: 'People'
  },
  {
    number: '03',
    text: 'Tier 0 to Tier 3 support services according to industry’s best practices',
    title: 'Full-cycle support'
  },
  {
    number: '04',
    text: 'In-depth knowledge of modern technology stacks, enterprise software, and cloud services',
    title: 'Full-stack engineering'
  },
  {
    number: '05',
    text: 'Unique combination of strong technical support culture and vast experience in cutting-edge Big Data, Cloud, and IoT technologies',
    title: 'Unique skill set & talent'
  }
];
export const TECHNICAL_SUPPORT_INSIGHTS_HEADING = 'Insights';

// software development

export const SERVICE_DEVELOPMENT_HEADING = 'Software Development'

export const SERVICE_DEVELOPMENT_SUBHEADING = 'Full-cycle software development services based on modern technology stacks, transparent delivery processes, and rigorous quality assurance standards.';

export const DEVELOPMENT_EXPERTISE_HEADING =
  '20+ Years of Software Development Expertise';
export const DEVELOPMENT_EXPERTISE_LEFT_PARAGRAPH =
  'Custom software development is our primary area of expertise, which we’ve grown through decades of work with top companies from various industries. By combining the latest technologies with time-proven solutions, we deliver software that lays a solid foundation for our clients’ business transformations. As a full service technology partner for many of our clients, we offer a complete delivery process that is fully transparent and easily adaptable to changing requirements. We can also augment your own delivery teams or join ongoing projects to help with advanced technologies or pressing deadlines.';
export const DEVELOPMENT_EXPERTISE_RIGHT_PARAGRAPH =
  'As a software development company specializing in cutting-edge Big Data, cloud, Internet of Things, ML/AI, mobile and other technologies, we have our software engineers deeply involved in future-proof applications and complex business problems. CyberVision is also a listed Google Cloud partner, which makes us a certified and reliable solution integrator for the Google Cloud Platform stack. Whether you\'re starting from scratch or upgrading your legacy systems to modern technologies we can put your company onto digital rails and provide a precise roadmap for your business case.';
export const DEVELOPMENT_EXPERTISE_CARDS = [
  {
    title: 'Web',
    icon: 'Web',
  },
  {
    title: 'Desktop',
    icon: 'Desktop',
  },
  {
    title: 'Mobile',
    icon: 'Mobile',
  },
  {
    title: 'Cloud',
    icon: 'Cloud',
  },
  {
    title: 'Embedded',
    icon: 'Embedded',
  },
  {
    title: 'Enterprise',
    icon: 'Enterprise',
  },
  {
    title: 'Analytics',
    icon: 'Analytics',
  },
  {
    title: 'R&D',
    icon: 'R&D',
  },
  {
    title: 'AI',
    icon: 'AI',
  },
  {
    title: 'Blockchain',
    icon: 'Blockchain',
  },
  {
    title: 'IoT',
    icon: 'IoT',
  },
];

export const DEVELOPMENT_SERVICES_HEADING = 'Our Software Development Services';
export const DEVELOPMENT_SERVICES_TEXT =
  'CyberVision team delivers a broad spectrum of enterprise software solutions that match the business needs of our clients and are easy to deploy, scale, and maintain. Whether your company takes its first steps towards a digital enterprise or has been in the technology industry for years, we can bring a perfect set of skills and qualifications to help you succeed with your unique project.';
export const DEVELOPMENT_SERVICES_CARDS = [
  {
    title: ['Software design', 'and development'],
    icon: 'development',
  },
  {
    title: ['System', 'integration'],
    icon: 'integration',
  },
  {
    title: ['Legacy system', 'upgrade'],
    icon: 'upgrade',
  },
  {
    title: ['Data', 'management'],
    icon: 'data-management',
  },
  {
    title: ['System security', 'implementation'],
    icon: 'security',
  },
  {
    title: ['Quality', 'assurance'],
    icon: 'qa',
  },
];

export const DEVELOPMENT_DEDICATED_TEAM_HEADING = 'Dedicated Development Team';
export const DEVELOPMENT_DEDICATED_TEAM_TEXT = ['CyberVision provides multiple engagement models to ensure that our clients have a dedicated development team that blends in perfectly into their specific project or organization.', 'For example, this could be a team that is hired for your needs and fully managed by you; this could be also a team managed by CyberVision, which would allow you to focus solely on scope of work and milestones, leaving day-to-day routine management to us; or this could be a project-based engagement, where we take full responsibility for the project delivery according to your requirements and your project plan.'];
export const DEVELOPMENT_DEDICATED_TEAM_CARDS = [
  {
    icon: 'fully-dedicated-team',
    title: 'Fully dedicated team'
  },
  {
    icon: 'managed-team',
    title: 'Managed team'
  },
  {
    icon: 'project-team',
    title: 'Project team'
  }
];

export const DEVELOPMENT_QA_HEADING = 'End-To-End Quality Assurance';
export const DEVELOPMENT_QA_TEXT = 'CyberVision team are experts in production-ready solutions. We ensure thorough quality assurance and control through the entire project lifecycle, applying industry best practices and specific QA methodologies used by our clients. Our software solutions come with comprehensive documentation, end-to-end automated tests, and in-depth QA reports, which - apart from ensuring transparent quality control - make our solutions easy to maintain on production and introduce further upgrades.';

export const DEVELOPMENT_AGILE_HEADING = 'Agile Delivery Model';
export const DEVELOPMENT_AGILE_TEXT =
  'We commonly abide by the Agile approach to software development, which is the best way to maximize collaboration with the customer, shorten delivery cycles, and continuously optimize go-to-market strategies. To keep everyone on the same page, we use process optimization frameworks such as Scrum, Kanban, and CMMi, which ensure a highly efficient and transparent project flow for each party. We also make a strong emphasis on in-depth quality management as we go from one project phase to another, making sure there’s no technical debt accumulation and each new release represents a perfectly viable product for our clients.';
export const DEVELOPMENT_ADVANTAGES_HEADING = 'CyberVision Advantages';
export const DEVELOPMENT_ADVANTAGES_CARDS = [
  {
    title: 'Google Cloud Partner',
    icon: 'cloud',
    text:
      'Our sharp focus on modern cloud solutions has led us to become a Google Cloud Partner for system integration and application development on Google Cloud Platform (GCP). For companies wanting to migrate their operations to modern cloud-based infrastructure, we can offer the entire spectrum of services for fast and efficient adoption of the GCP stack. Apart from software engineers, our team is also composed of CloudOps experts, data scientists, and system analysts who ensure that every client’s business need is fully addressed with an appropriate type of technology or process optimization.',
  },
  {
    title: 'Trusted by large enterprises and startups',
    icon: 'high',
    text:
      'We are fully versed in the operational aspects of enterprise software development, including planning, financing, project management, etc; we also understand the unique needs of dynamic start-ups and how to manage lean product development. Over the years, we’ve been trusted by industry giants from Fortune 100 and Fortune 500 lists as well as by visionary teams before they became global brands. We are also fluent with diverse cultural backgrounds and management practices, whether it’s a company from the US, Asia, Europe, Middle East, commercial or government sectors.',
  },
  {
    title: 'Experts in trending technologies',
    icon: 'trending',
    text:
      'We have a solid track record in dealing with the most innovative technologies that fuel modern businesses’ digital transformation, such as Big Data, cloud, ML, or IoT. We’ve been working for prominent providers of the corresponding technology solutions as well as for companies who employ such solutions for their daily activities. That’s why, as a technology partner, we also help our clients see the bigger picture and pick what they really need for efficient innovations.',
  },
  {
    title: 'Efficient delivery model',
    icon: 'scrum',
    text:
      'For the best results and maximum flexibility for our clients, we offer a variety of delivery models and team management workflows. We usually recommend Agile practices to ensure frequent releases and early delivery of a minimal viable product (MVP). We also commonly start our engagements with a proof-of-concept delivery so that our clients could have a clear vision of what to expect at the end of the project.',
  },
];
export const DEVELOPMENT_INSIGHTS_HEADING = 'Insights';

// IT managed services

export const IT_MANAGED_SERVICES_HEADING = 'IT Managed Services';
export const IT_MANAGED_SERVICES_SUBHEADING = 'CyberVision offers an extensive list of IT managed services and is committed to delivering maximum support for our clients’ IT operations whenever they need it. By combining a flexible service delivery model with our profound expertise in modern technology, we can go from low-level infrastructure support to complete outsourcing of our clients’ IT operations.';
export const IT_MANAGED_SERVICES_PROVIDER_HEADING = 'Managed Service Provider for Your Diverse IT Needs';
export const IT_MANAGED_SERVICES_PROVIDER_SUBHEADING = 'Acting as a one-stop shop for our clients’ IT needs, CyberVision has not only excelled as a software development and technology partner, but also as a reliable provider of managed IT services. We complement our strong expertise in such fields as Big Data, Cloud, IoT, Embedded, and ML/AI with the general-purpose IT services to make sure nothing stands in the way of our clients’ success stories.';
export const IT_MANAGED_SERVICES_PROVIDER_CONTENT = [
  {
    title: 'IT infrastructure support',
    icon: 'infrastructure-support',
    listItems: [
      'Corporate IT network management', 'Software installation and updates', 'User access management',
      'Software integration', 'System health & security monitoring', 'Issue tracking & verification'
    ]
  },
  {
    title: 'Value-added managed services',
    icon: 'value-added-managed-services',
    listItems: [
      'IT infrastructure design', 'Performance monitoring & analysis', 'Software maintenance',
      'Full-cycle incident management', 'Scalability planning', 'Cloud storage optimization'
    ]
  },
  {
    title: 'Outsourced IT & technology services',
    icon: 'outsourced',
    listItems: [
      'Fully managed IT infrastructure', 'On-demand engineering services', 'DevOps as a service',
      'Corporate security management', '24/7 customer support', 'Data analytics'
    ]
  }
];
export const IT_MANAGED_SERVICES_ADVANTAGES_CONTENT = [
  {
    number: '01',
    text: '20+ years of experience in technical and IT support',
    title: 'Expertise'
  },
  {
    number: '02',
    text: 'Strong team of software engineers and support specialists',
    title: 'People'
  },
  {
    number: '03',
    text: ' Tier 0 to Tier 3 support services according to industry’s best practices',
    title: 'Full-cycle support'
  },
  {
    number: '04',
    text: 'In-depth knowledge of modern technology stacks, enterprise software, and cloud services',
    title: 'Full-stack engineering'
  },
  {
    number: '05',
    text: 'Unique combination of strong technical support culture and vast experience in cutting-edge Big Data, Cloud, and IoT technologies',
    title: 'Unique skill set & talent'
  }
];
export const IT_MANAGED_SERVICES_INSIGHTS_HEADING = 'Insights';

// software development services
// new service 1
export const S_1_MENU_TITLE = 'Custom software development'

export const S_1_BLOCK_1_HEADING = 'Custom software development services'

export const S_1_BLOCK_1_INTRO = 'Cybervision is more than just a custom software development company — we’re your dedicated partner in driving digital transformation. With deep industry expertise, we craft solutions that deliver measurable impact. You can rely on us to propel your business toward success.';

export const S_1_BLOCK_2_HEADING = 'Our Software Development Services';

export const S_1_BLOCK_2_INTRO = 'At Cybervision, we leverage the latest technologies to deliver custom software development solutions that enhance efficiency and scalability, ensuring your business stays ahead of the curve. By keeping pace with advancements, we future-proof your systems to support seamless growth, allowing you to focus on what truly matters — achieving your business goals.';

export const S_1_BLOCK_2_CARDS = [
  {
    "title": "Software Consulting",
    "text": [
      "We deliver software consulting services that provide actionable insights and strategic guidance to enhance your business ideas and development projects.",
      "Our seasoned consultants analyze your operations, understand your vision, and deliver tailored solutions designed to drive growth, improve efficiency, and maximize profitability."
    ],
    "icon": "ic-1"
  },
  {
    "title": "Custom Software Development",
    "text": [
      "Our custom software development services align precisely with your unique business needs.",
      "Using an agile development methodology, we ensure faster delivery, iterative improvements, enhanced scalability, and seamless integration, enabling your software to grow with your business."
    ],
    "icon": "ic-2"
  },
  {
    "title": "Software Product Development",
    "text": [
      "From ideation to release and beyond, we specialize in end-to-end software product development.",
      "By strategizing roadmaps and assessing risks, we help you gain a competitive edge with successful, market-ready software solutions."
    ],
    "icon": "ic-3"
  },
  {
    "title": "Artificial Intelligence Software Development",
    "text": [
      "We provide custom AI-driven software solutions designed to meet your specific business needs.",
      "With expert guidance, you can leverage cutting-edge AI technologies to enable smarter decisions and achieve better results."
    ],
    "icon": "ic-4"
  },
  {
    "title": "Software Migration and Integration",
    "text": [
      "Our team enhances your system functionality while ensuring compliance and smooth integration with third-party services.",
      "Whether modernizing infrastructure or optimizing legacy systems, we’re here to support your goals."
    ],
    "icon": "ic-5"
  },
  {
    "title": "API Development",
    "text": [
      "We design robust, scalable APIs that connect your applications and streamline data flow.",
      "Our expertise ensures seamless integration with other systems, improving connectivity and enhancing the functionality of your applications."
    ],
    "icon": "ic-6"
  },
  {
    "title": "Agile Application Development",
    "text": [
      "Our agile development methodologies deliver high-quality software quickly and efficiently.",
      "Through a collaborative approach, iterative development, and rapid prototyping, we focus on meeting your evolving needs."
    ],
    "icon": "ic-7"
  },
  {
    "title": "UI/UX Design",
    "text": [
      "We craft visually appealing and user-friendly interfaces for applications, websites, and digital platforms.",
      "With a user-first design philosophy and mobile-friendly focus, we ensure an engaging experience for your audience."
    ],
    "icon": "ic-8"
  },
  {
    "title": "Application Modernization",
    "text": [
      "Modernizing legacy applications is essential to stay competitive.",
      "Using advanced technologies, we enhance security, boost performance, and align your software with today’s business needs, helping you adapt to future challenges."
    ],
    "icon": "ic-9"
  },
  {
    "title": "Software Support and Maintenance",
    "text": [
      "Our round-the-clock support ensures your software runs smoothly, minimizing downtime and interruptions.",
      "With our maintenance services, you can focus on growth while we handle your technology needs."
    ],
    "icon": "ic-10"
  },
  {
    "title": "Cloud Development",
    "text": [
      "Transitioning to the cloud has never been easier.",
      "We help you migrate seamlessly, offering flexibility, scalability, and cost savings, ensuring your business stays agile and ready for what’s next."
    ],
    "icon": "ic-11"
  },
  {
    "title": "Software Testing and QA",
    "text": [
      "Our meticulous testing process eliminates bugs, improves performance, and strengthens security.",
      "With our quality assurance services, your software is launch-ready, delivering reliability and confidence."
    ],
    "icon": "ic-12"
  },
  {
    "title": "DevOps",
    "text": [
      "Streamline your development processes with our DevOps expertise.",
      "We improve collaboration, automate workflows, and ensure faster, more reliable delivery to keep your projects moving smoothly."
    ],
    "icon": "ic-13"
  }
];

export const S_1_BLOCK_3_HEADING = 'Industries we serve'

export const S_1_BLOCK_3_USE_CASES_CONTENT = [
  {
    title: 'Energy & Utilities',
    text: 'Drive innovation in energy and utilities with software that enhances resource management, supports smart grid operations, and promotes sustainability. Our tools help organizations cut costs and meet green energy goals.',
    image: '9'
  },
  {
    title: 'Transport and Logistics',
    text: 'Simplify your logistics operations with customized software that streamlines fleet management, route optimization, shipment tracking, and warehouse management. Our solutions are built to enhance operational efficiency and give you a competitive edge in the transport and logistics sector.',
    image: '5'
  },
  {
    title: 'Agriculture',
    text: 'Empower the agricultural industry with smart software solutions that improve crop monitoring, streamline farm management, and optimize supply chain operations. These tools enable farmers to make informed decisions, increase productivity, and ensure sustainable practices.',
    image: '7'
  },
  {
    title: 'Banking',
    text: 'Transform your banking operations with customized software solutions designed to enhance efficiency and customer satisfaction. From secure account management to seamless transactions, our tailored banking software ensures your institution stays ahead in the digital era, improving every aspect of the customer experience.',
    image: '1'
  },
  {
    title: 'FinTech',
    text: 'Enhance your financial services with secure, reliable, and cutting-edge software solutions. Our advanced FinTech software development services streamline operations, simplify compliance, and accelerate product launches. We empower businesses to offer modern financial experiences that meet the evolving needs of their customers.',
    image: '2'
  },
  {
    title: 'Retail',
    text: 'Boost your retail operations with software solutions tailored to your industry. Our Point of Sale systems, eCommerce platforms, and inventory management tools enhance customer satisfaction, reduce manual errors, and improve operational efficiency — helping your business thrive in a competitive market.',
    image: '3'
  },
  {
    title: 'Manufacturing',
    text: 'Revolutionize your manufacturing processes with custom software designed to automate and optimize. From production planning to inventory and supply chain management, our industry-specific solutions improve efficiency, cut costs, and boost productivity, enabling sustainable growth.',
    image: '4'
  },
  {
    title: 'Insurance',
    text: 'Modernize your insurance operations with custom software solutions tailored to policy management, claims automation, underwriting, and customer portals. Our technology-driven approach helps streamline processes, enhance customer satisfaction, and improve risk management capabilities.',
    image: '6'
  },
  {
    title: 'Real Estate',
    text: 'Revolutionize real estate management with platforms that automate transactions, simplify property listings, and enhance customer engagement. Our tools help real estate companies close deals faster and manage properties efficiently.',
    image: '8'
  },
  {
    title: 'Construction',
    text: 'Transform construction management with platforms designed to streamline projects, improve team collaboration, and optimize resource allocation. Our solutions ensure timely project delivery and effective cost management.',
    image: '10'
  },
  {
    title: 'eCommerce',
    text: 'Elevate your eCommerce business with platforms designed for personalized shopping experiences, secure payment processes, and effective inventory management. Our solutions foster growth, customer loyalty, and operational efficiency.',
    image: '11'
  },  
];

export const S_1_BLOCK_4_HEADING = 'Ready to talk to our experts?';
export const S_1_BLOCK_4_SUBHEADING = ['Send us a note and we’ll set up a meeting to answer all the technical questions you might have.'];

export const S_1_BLOCK_5_HEADING = 'Dedicated Development Team';
export const S_1_BLOCK_5_SUBHEADING = 'CyberVision provides multiple engagement models to ensure that our clients have a dedicated development team that blends in perfectly into their specific project or organization.';

export const S_1_BLOCK_5_CONTENT = [
  {
    title: 'Our Engagement Models',
    text: 'Discover our flexible engagement models, tailored to align with your unique software development requirements. These models are designed to ensure seamless collaboration and successful project outcomes at every stage.',
    icon: '1'
  },
  {
    title: 'Dedicated Teams',
    text: 'Hire a dedicated software development team that works exclusively for your project as an extension of your in-house team. Say goodbye to the challenges of hiring, training, and managing infrastructure. With our dedicated team model, we help you turn your business ideas into reality while maintaining the highest quality standards.',
    icon: '2'
  },
  {
    title: 'Staff Augmentation',
    text: 'Scale your development capabilities with our IT staff augmentation services, recognized for their reliability and efficiency. Fill resource gaps by adding skilled IT professionals to your existing team. Our experts seamlessly integrate with your in-house staff, accelerating your development process without the hassle of permanent hiring.',
    icon: '3'
  },
  {
    title: 'Software Outsourcing',
    text: 'Whether you\'re starting from scratch or upgrading an existing solution, our software outsourcing services offer cost-effective development without compromising quality. Let us handle your software needs with innovative solutions and the latest features to help your business stay competitive.',
    icon: '4'
  },
];

export const S_1_BLOCK_6_HEADING = 'Agile Delivery model';
export const S_1_BLOCK_6_INTRO = 'We commonly abide by the Agile approach to software development, which is the best way to maximize collaboration with the customer, shorten delivery cycles, and continuously optimize go-to-market strategies. To keep everyone on the same page, we use process optimization frameworks such as Scrum, Kanban, and CMMi, which ensure a highly efficient and transparent project flow for each party. We also make a strong emphasis on in-depth quality management as we go from one project phase to another, making sure there’s no technical debt accumulation and each new release represents a perfectly viable product for our clients.';


export const S_1_BLOCK_7_HEADING = 'FAQ';
export const S_1_BLOCK_7_INTRO = '';
export const S_1_BLOCK_7_CARDS = [
  {
    "title": "Do you provide NDA and IP protection for my Software Development project?",
    "text": [
      "Absolutely. You will have full ownership of your project, including NDA, copyright, source code, intellectual property rights, confidential agreements, and other necessary MoUs.",
      "We prioritize your confidentiality and ownership."
    ]
  },
  {
    "title": "Will you assign a dedicated 'Project Manager – Scrum Master' as a point of contact?",
    "text": [
      "Yes, we assign a dedicated Project Manager, who also serves as a Scrum Master, for every project.",
      "This individual will be your primary point of contact throughout the engagement, ready to assist with any queries, concerns, or requirements."
    ]
  },
  {
    "title": "What project management tools do you use?",
    "text": [
      "We use industry-leading project management tools such as Jira, Trello, Pivotal Tracker, Asana, and Slack.",
      "These platforms allow you to monitor developer productivity, track project progress, assign tasks, and stay updated on the daily status of your development work."
    ]
  },
  {
    "title": "Do you provide support and maintenance services after deployment?",
    "text": [
      "Yes, we offer comprehensive post-deployment support and maintenance.",
      "This includes bug fixes, framework upgrades, continuous monitoring, and resolution of technical issues to ensure your product remains optimal and up-to-date."
    ]
  },
  {
    "title": "What are the stages of software development services?",
    "text": [
      "The software development life cycle typically includes the following stages:",
      "- Requirement Gathering: Understanding the client’s needs and objectives.",
      "- Design: Crafting architecture and user interface.",
      "- Coding: Writing functional and optimized code.",
      "- Testing and Bug Fixing: Ensuring the software meets quality standards.",
      "- Maintenance: Providing ongoing support and upgrades."
    ]
  },
  {
    "title": "How long does it typically take to develop custom software?",
    "text": [
      "The timeline varies based on complexity.",
      "A Minimum Viable Product (MVP) can be developed in 2-3 months, while a comprehensive solution might take 6 months to a year or more."
    ]
  },
  {
    "title": "How does Cybervision handle data security during software development?",
    "text": [
      "We prioritize data security by implementing encryption, secure coding practices, and regular security audits.",
      "These measures ensure your software is protected from vulnerabilities during development and after deployment."
    ]
  },
  {
    "title": "Why would a business choose custom software over off-the-shelf solutions?",
    "text": [
      "Custom software is tailored to a business's exact requirements, offering superior flexibility and scalability.",
      "It integrates seamlessly with existing processes, ensuring a long-term fit that off-the-shelf solutions often cannot provide."
    ]
  },
  {
    "title": "How to choose a software development services company?",
    "text": [
      "Choosing the right partner for your software project requires diligence.",
      "Start by verifying the company’s track record, contacting past clients, and assessing their expertise.",
      "Shortlist companies that demonstrate reliability and a strong alignment with your goals.",
      "This due diligence ensures a partnership that can deliver success over months or even years."
    ]
  },
  {
    "title": "What are the biggest benefits of outsourcing a software development services firm?",
    "text": [
      "Outsourcing software development offers numerous benefits, including:",
      "- Cost Efficiency: Save on hiring and infrastructure costs.",
      "- Access to Expertise: Leverage specialized skills and technologies.",
      "- Agility and Quality: Focus on your core business while experienced teams handle development.",
      "- Scalability: Quickly adapt to changing requirements and demands."
    ]
  },
  {
    "title": "How to reduce software development services costs?",
    "text": [
      "Cost reduction can be achieved through:",
      "- Agile Practices: Promoting clarity, collaboration, and efficient delivery.",
      "- Planning and Requirement Clarity: Preventing wasted effort and misaligned development.",
      "- Focus on Value: Avoid over-engineering by prioritizing impactful deliverables.",
      "By adopting Agile methodologies and refining processes, teams can minimize inefficiencies and optimize output."
    ]
  }
]

export const S_1_BLOCK_TOOLS = {
  heading: ['Our Services:'],
  content: [
    {
      "title": "Back-end",
      "body": ["Python", "Java", "Ruby", "PHP", "C#", "Node.js", "Go", "Kotlin", "Rust", "Scala", ".NET"]
    },
    {
      "title": "Front-end",
      "body": ["HTML", "CSS", "JavaScript", "TypeScript", "React.js", "Angular", "Vue.js", "Bootstrap", "Sass/SCSS"]
    },
    {
      "title": "Desktop",
      "body": ["Java", "C#", "C++", "Python", "Electron", "Qt", "WinForms", "GTK+", "Swift"]
    },
    {
      "title": "Mobile",
      "body": ["Swift", "Objective-C", "Java", "Kotlin", "React Native", "Flutter", "Xamarin", "Ionic", "PhoneGap", "Cardova", "Unity", "Accelerator Titanium"]
    },
    {
      "title": "Big Data",
      "body": ["Hadoop", "Apache Spark", "Apache Kafka", "Apache HBase", "Apache Cassandra", "MongoDB", "Apache Hive", "Apache Pig", "Apache Storm", "Elasticsearch"]
    },
    {
      "title": "AI/ML",
      "body": ["ML-Agents", "ChatBot", "TensorFlow", "DialogFlow", "PyTorch", "Scikit-learn", "Microsoft  Azure ML Studio", "IBM Watson Studio", "Hadoop"]
    },
    {
      "title": "Data Analytics & Visualization",
      "body": ["Power BI", "Tableau", "Qlik", "Google Data Studio", "Domo", "Apache Superset", "SAS Visual Analytics", "Unity Analytics"]
    },
    {
      "title": "Devops",
      "body": ["Kubernetes", "Helm", "Terraform", "Docker", "Ansible", "Chef", "Puppet", "vCenter Server", "vSphere", "VMware Tanzu", "VMware ESXi", "GitLab", "Jenkins", "GitHub Actions", "Bitbucket", "Grafana", "Prometheus", "ELK (Elasticsearch, Logstash, Kibana)", "New Relic, Splunk", "Amazon Web Services (AWS)", "Google Cloud", "Azure", "OpenStack"]
    },
    {
      "title": "Monitoring",
      "body": ["Grafana", "Prometheus", "ELK", "New Relic", "Splunk"]
    }
  ],
};
export const S_1_BLOCK_TOOLS_HEADING = 'TOOLS & TECHNOLOGIES OUR SOFTWARE DEVELOPERS USE';
export const S_1_BLOCK_TOOLS_INTRO = 'Our team utilizes a robust set of tools, state-of-the-art technologies, and modern methods to deliver exceptional software solutions that drive business growth and innovation. Here\'s a glimpse of the technical stack we rely on for successful project delivery:';


// ---------- Enterprise Software Development 
export const S_2_MENU_TITLE = 'Enterprise Software Development'

export const S_2_BLOCK_1_HEADING = 'Enterprise Software Development Services'

export const S_2_BLOCK_1_INTRO = 'We recognize the intricate challenges of enterprise software development. When your organization faces mission-critical projects that require seamless transitions with zero downtime, strict adherence to global compliance standards, and robust defenses against advanced threats, Cybervision is the partner you can trust to deliver excellence.';

export const S_2_BLOCK_2_HEADING = 'Our Enterprise Software Development Services';

export const S_2_BLOCK_2_CARDS = [
  {
    "title": "Custom Enterprise Software Development",
    "text": [
      "Our team of experts uses a diverse range of programming languages, frameworks, and libraries to deliver robust front-end and back-end solutions.",
      "By following Agile and DevOps best practices, we build enterprise-grade software from scratch, equipping it with customized modules and features tailored to your unique needs."
    ]
  },
  {
    "title": "Enterprise Mobile App Development",
    "text": [
      "Transform your systems into mobile-friendly solutions to boost productivity and keep your team efficient, regardless of their location.",
      "All while maintaining top-notch security."
    ]
  },
  {
    "title": "Enterprise Big Data Services",
    "text": [
      "Empower your decision-making processes by unlocking actionable insights from your data.",
      "We provide onsite, cloud, and hybrid big data solutions that are efficient, cost-effective, and easy to manage.",
      "Helping you stay ahead in the data-driven age."
    ]
  },
  {
    "title": "IT Consulting",
    "text": [
      "Our IT consulting services assist organizations in implementing custom enterprise software and navigating potential adoption challenges.",
      "We provide guidance on identifying suitable use cases, selecting the optimal tech stack and deployment model, estimating TCO and ROI, and creating a realistic project roadmap to ensure success."
    ]
  },
  {
    "title": "Technical Audit",
    "text": [
      "We conduct in-depth technical audits to examine the core components, technology stack, and operational aspects of your enterprise software.",
      "Our audits identify design and security vulnerabilities, provide actionable recommendations, and ensure alignment with industry standards and best practices."
    ]
  },
  {
    "title": "Software Integration Services",
    "text": [
      "Simplify your operations by connecting disparate systems to streamline workflows.",
      "We design secure data handoffs and develop custom applications to centralize access, making your existing software more efficient and integrated."
    ]
  },
  {
    "title": "Software Maintenance",
    "text": [
      "Our software maintenance services cover adaptive, perfective, corrective, and preventive needs.",
      "We ensure your enterprise software evolves with new usage scenarios, resolves performance issues, and prevents latent faults from disrupting your operations."
    ]
  },
  {
    "title": "Enterprise Software Integration",
    "text": [
      "Cybervision seamlessly integrates your enterprise software with corporate systems and IoT networks.",
      "Using APIs, message-oriented middleware, ETL/ELT data synchronization, and iPaaS solutions, we eliminate data silos and ensure an uninterrupted flow of information across your technology ecosystem."
    ]
  },
  {
    "title": "Software Testing and QA Consulting",
    "text": [
      "Our experienced QA professionals deliver high-grade software testing and QA consulting services.",
      "Leveraging both manual and automated testing methodologies, we ensure your software’s reliability, performance, and functionality.",
      "Guaranteeing flawless product delivery."
    ]
  },
  {
    "title": "Enterprise Cloud Solution",
    "text": [
      "Our cloud specialists collaborate with enterprise software experts to provide scalable, flexible, and cost-efficient cloud solutions.",
      "From migration and deployment to ongoing management of your business applications and IT infrastructure, we enable innovation, agility, and growth using the latest in cloud computing technology."
    ]
  }
];

export const S_2_BLOCK_3_HEADING = 'Advanced technologies for enterprise software';

export const S_2_BLOCK_3_CARDS = [
  {
    "title": "Artificial Intelligence",
    "text": "We integrate multiple branches of AI, such as machine learning, computer vision, and natural language processing, to develop custom systems. These systems excel in detecting patterns and anomalies in big data, learning from experience to enhance their operational capabilities, deriving insights from visual inputs, and enabling seamless human/machine interactions."
  },
  {
    "title": "Internet of Things (IoT)",
    "text": "Our team specializes in configuring and deploying extensive networks of interconnected devices, such as RFID tags, sensors, beacons, and wearables. These networks collect real-time data for analytical and operational purposes, supporting use cases like remote patient monitoring in healthcare, track-and-trace in logistics, and predictive maintenance in transportation."
  },
  {
    "title": "Robotic Process Automation (RPA)",
    "text": "We deploy RPA bots across your business processes to automate repetitive tasks or assist your workforce with administrative operations. From claim processing in insurance to bank reconciliation in accounting or payroll management in HR, our RPA solutions improve efficiency while reducing manual workloads."
  },
  {
    "title": "Blockchain",
    "text": "Cybervision enables your organization to leverage blockchain technology for transparent and secure transactions. From self-executing smart contracts to asset tracking in supply chains and streamlined anti-money laundering (AML) and KYC processes via incorruptible data storage, our solutions bring trust and efficiency to your operations."
  },
  {
    "title": "Data Science",
    "text": "For businesses handling vast amounts of data, our data science expertise offers tools and frameworks to optimize operations and accelerate decision-making. Leverage our solutions to uncover insights, automate analytics, and improve overall efficiency."
  },
  {
    "title": "Low-Code/No-Code Development",
    "text": "We assist in developing enterprise applications using low-code/no-code platforms. With intuitive tools like drag-and-drop interfaces and pull-down menus, your company can quickly build, deploy, and scale applications without requiring extensive coding knowledge."
  },
  {
    "title": "Cloud Computing",
    "text": "Our cloud consulting services help you leverage platforms like Azure, AWS, and Google Cloud to create a flexible, scalable, and interconnected business environment. With our guidance, you can ensure seamless integration and management of all your cloud-based operations."
  }
];

export const S_2_WHY_HEADING = 'Why choose us?';
export const S_2_WHY_DESCRIPTION =
  'With a customer retention ratio exceeding 90%, we pride ourselves on fostering lasting client relationships. Whether your goal is to develop scalable software solutions that optimize internal processes or integrate applications for a connected software ecosystem, we’re here to help. Utilize our enterprise application development services to bridge resource gaps, unlock hidden potential, and streamline your business operations for sustained growth.';
export const S_2_WHY_CARDS = [
  {
    title: 'Digital Automation',
    text:
      'Our business analysts conduct in-depth research to craft tailored automation solutions that align with your specific goals. From initial estimations to detailed specifications and project scoping, we deliver a clear roadmap for automating business processes efficiently and effectively.',
  },
  {
    title: 'Superior Technical Proficiency',
    text:
      'Our cross-functional teams of engineers, analysts, solutions architects, and testing experts work collaboratively to deliver consistent results. By addressing challenges at every level, they minimize technical risks with strategic architecture design and ensure complete transparency throughout the project lifecycle.',
  },
  {
    title: 'Full-Stack Team for Project Success',
    text:
      'Our comprehensive team includes business analysts, UX/UI designers, software developers, QA engineers, and DevOps specialists, all experienced in custom enterprise app development. From custom integrations to complete software development, we handle every aspect of your project seamlessly under one roof, ensuring exceptional outcomes.',
  },
];

export const S_2_BLOCK_4_ADVANTAGES = [
  {
    "number": "01",
    "title": "Design Stage",
    "text": "Our design team crafts user-centric designs that reflect your brand identity while ensuring an engaging and intuitive user experience. We focus on creating interfaces that combine aesthetics with functionality to enhance user satisfaction."
  },
  {
    "number": "02",
    "title": "Development Process",
    "text": "Using the latest technologies and agile methodologies, we develop your software with a focus on scalability, efficiency, and robustness. Best practices guide every step, ensuring the solution meets your current needs while adapting to future growth."
  },
  {
    "number": "03",
    "title": "Support & Maintenance",
    "text": "Our partnership doesn’t end with deployment. We offer ongoing support, regular updates, and maintenance to ensure your software remains secure, efficient, and aligned with your evolving requirements."
  },
  {
    "number": "04",
    "title": "Smooth Deployment",
    "text": "Deploying enterprise solutions requires precision. We manage the transition seamlessly, ensuring legacy systems remain operational while integrating new applications effectively to minimize disruption."
  },
  {
    "number": "05",
    "title": "Rigorous Testing",
    "text": "Quality assurance is embedded into every step of our development process. Through continuous testing and comprehensive QA procedures, we validate functionality, reliability, and performance long before your solution goes live."
  }
];

export const S_2_BLOCK_5_HEADING = 'Our collaboration models';

export const S_2_BLOCK_5_CARDS = [
  {
    title: 'Staff Augmentation',
    text:
      'Strengthen your in-house team with our experienced developers. Whether you need specialized skills to tackle a critical challenge or extra hands to meet tight deadlines, our professionals seamlessly integrate with your team to help you achieve your goals.',
  },
  {
    title: 'Dedicated Team',
    text:
      'You set the vision, and we handle the execution. Our expert developers become a remote extension of your team, dedicated to your project for as long as needed. Let us take care of the technical heavy lifting while you focus on strategic direction.',
  },
  {
    title: 'Fully Managed Delivery',
    text:
      'Provide us with your requirements, and we’ll handle the rest. From planning to execution, our team ensures a high-quality result with minimal input required from your end, allowing you to stay focused on your core business priorities.',
  },
];

export const S_2_BLOCK_6_HEADING = 'FAQ';
export const S_2_BLOCK_6_CARDS = [
  {
    "title": "What are the advantages of custom enterprise software compared to off-the-shelf solutions?",
    "text": [
      "Off-the-shelf solutions are often ideal for small businesses seeking quick deployment and cost savings, even if they sacrifice some efficiency.",
      "However, at the enterprise level, efficiency and customization are paramount. Custom enterprise software is built to address unique business needs, ensuring scalability, seamless integration, and a tailored fit for complex workflows.",
      "Hybrid approaches, combining ready-made modules with custom features, can also be an effective option."
    ]
  },
  {
    "title": "How is enterprise software different from other software systems?",
    "text": [
      "Enterprise software is designed to address specific, often complex, organizational needs.",
      "It supports a company’s strategic goals by solving intricate challenges, streamlining processes, and integrating with third-party systems.",
      "Unlike general-purpose software, enterprise solutions are tailored to meet precise business objectives, are highly scalable, secure, and reliable, and often serve multiple stakeholders across the organization."
    ]
  },
  {
    "title": "What are the key benefits of adopting enterprise software?",
    "text": [
      "Adopting enterprise software can significantly enhance efficiency and productivity.",
      "It simplifies complex tasks, automates workflows, and saves both time and resources.",
      "Additionally, it brings transparency, traceability, and standardization to business processes, enabling better compliance and oversight.",
      "Enterprise software also supports scalability, ensuring consistent quality as the organization grows."
    ]
  },
  {
    "title": "How much does enterprise software development cost?",
    "text": [
      "The cost of enterprise software development varies based on several factors, including the complexity of the project, software size, design intricacy, required integrations, team expertise, and location.",
      "Timelines also influence costs. For a detailed estimate tailored to your needs, reach out to us for a free quote."
    ]
  },
  {
    "title": "How long does it take to develop enterprise application software?",
    "text": [
      "The timeline for developing enterprise software depends on multiple factors, such as the complexity of the solution, desired features, functionality, and end-user requirements.",
      "From initial planning to final testing and release, a full-cycle project can take several months to a year, depending on the scope."
    ]
  },
  {
    "title": "Do you ensure NDA and intellectual property protection for my project?",
    "text": [
      "Absolutely. You retain 100% ownership of your project.",
      "This includes NDAs, copyright, source code, intellectual property rights, confidential agreements, and any other necessary documentation."
    ]
  },
  {
    "title": "Can a dedicated Project Manager or Scrum Master be assigned to my project?",
    "text": [
      "Yes, we can assign a dedicated Project Manager or Scrum Master to oversee your project.",
      "This individual will serve as your primary point of contact, ensuring smooth communication and providing support for any questions or issues that arise."
    ]
  },
  {
    "title": "Do you provide app maintenance and support services?",
    "text": [
      "Yes, we offer comprehensive app maintenance and support services.",
      "Our experts handle ongoing monitoring, bug fixes, performance optimization, security updates, and regular enhancements to ensure your application continues to operate smoothly and meets evolving business needs."
    ]
  }
]

