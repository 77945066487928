import {CDM_TITLE} from './cloud-development-and-migration';
import {DEVOPS_MENU_TITLE} from './devops';
import {GCP_MOBILE_LINK_TITLE, GCP_TITLE} from './google-cloud-platform';
import {GCDF_MENU_TITLE} from './google-cloud-data-fusion';
import {MTGC_MENU_TITLE} from './migration-to-google-cloud';
import {BIG_DATA, CDAP, IOT_DEVELOPMENT} from './services-names';
import {IT_MANAGED_SERVICES_HEADING, SERVICE_DEVELOPMENT_HEADING, SUPPORT_MENU_TITLE, S_1_MENU_TITLE, S_2_MENU_TITLE} from './services-pages';

export const SERVICES_MENU = [
  {
    anchorRef: {current: null},
    link: '/services/software-development',
    text: SERVICE_DEVELOPMENT_HEADING,
    mobileLinkText: SERVICE_DEVELOPMENT_HEADING,
    children: [
      {
        link: '/services/software-development/software-development-services',
        text: S_1_MENU_TITLE
      },
      {
        link: '/services/software-development/enterprise-software-development',
        text: S_2_MENU_TITLE
      },
    ]
  },
  {
    link: '/services/cdap-support',
    text: CDAP
  },
  {
    link: '/services/technical-support',
    text: SUPPORT_MENU_TITLE
  },
  {
    link: '/services/it-managed-services',
    text: IT_MANAGED_SERVICES_HEADING
  }
];
export const EXPERTISE_MENU = [
  {
    link: '/expertise/big-data-analytics',
    text: BIG_DATA
  },
  {
    link: '/expertise/iot-development',
    text: IOT_DEVELOPMENT
  },
  {
    link: '/expertise/devops',
    text: DEVOPS_MENU_TITLE
  },
  {
    anchorRef: {current: null},
    link: '/expertise/google-cloud-platform',
    text: GCP_TITLE,
    mobileLinkText: GCP_MOBILE_LINK_TITLE,
    children: [
      {
        link: '/expertise/google-cloud-platform/google-cloud-data-fusion',
        text: GCDF_MENU_TITLE
      },
      {
        link: '/expertise/google-cloud-platform/migration-to-google-cloud',
        text: MTGC_MENU_TITLE
      }
    ]
  },
  {
    link: '/expertise/cloud-development-and-migration',
    text: CDM_TITLE
  }
];
